import { GET_COUNTRIES, GET_INSTITUTIONS, GET_STATISTICS } from "../ActionTypes";

const INIT_VALUES = {
    countries: [],
    institutions: [],
    total_students: "",
    total_courses: "",
    course_categories: [],
};

export const GeneralReducer = (state = INIT_VALUES, action) => {
    
    switch (action.type) {
        case GET_COUNTRIES:
            return { ...state, countries: action.payload.data };

        case GET_INSTITUTIONS:
            return { ...state, institutions: action.payload.data };

        case GET_STATISTICS:
            return { 
                ...state, 
                total_students: action.payload.students, 
                total_courses: action.payload.courses,
            };

        default:
            return state;
    }

    
}


