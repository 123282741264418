import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import { UserReducer, GeneralReducer } from "./reducers";


const reducer = combineReducers({
    // this contains all reducers
    User: UserReducer,
    General: GeneralReducer,
});

const initialState = {
    
};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;  