import { ApiEndpoints, SERVER_REQUEST } from "../../shared";
import { GET_COUNTRIES, GET_INSTITUTIONS, GET_STATISTICS } from "../ActionTypes";

export const GetCountries = () => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_COUNTRIES, 'get', "", 'json' );
    
    await dispatch({
        type: GET_COUNTRIES,
        payload: response.data,
    });
    
    if (response.status === 200) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const GetInstitutions = (user_token) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_INSTITUTIONS, 'get', { user_token: user_token }, 'json' );
    
    await dispatch({
        type: GET_INSTITUTIONS,
        payload: response.data,
    });
    
    if (response.status === 200) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}

export const GetStatistics = (user_token) => async (dispatch, getState) => {
    const response = await SERVER_REQUEST(ApiEndpoints.GET_STATISTICS, 'get', { user_token: user_token }, 'json' );
    
    let students    = response.data.students;
    let courses     = response.data.courses;

    await dispatch({
        type: GET_STATISTICS,
        payload: {students: students, courses: courses},
    });
    
    if (response.status === 200) {
        return { status: "success", data: response.data };
    } else {
        return { status: "error", data: "An error occured" };
    }
}
