import { SIGN_UP, SIGN_IN, FORGET_PASSWORD, GET_USER_DETAILS } from "../ActionTypes";

const INIT_VALUES = {
    auth: [],
    user: [],
};

export const UserReducer = (state = INIT_VALUES, action) => {
    switch (action.type) {
        case SIGN_UP:
            return { ...state, auth: action.payload };

        case SIGN_IN:
            return { ...state, auth: {...action.payload}}

        case GET_USER_DETAILS:
            return { ...state, user: {...action.payload}}
        
        default:
            return state;
    }
}


